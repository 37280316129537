import React, {useContext, useEffect, useState} from 'react';
import {Password} from "primereact/password";
import {Dialog} from "primereact/dialog";
import {SelectButton} from "primereact/selectbutton";
import {useIntl} from "react-intl";
import {UserContext} from "../../context/UserContext";
import {Slider} from "primereact/slider";
import {AuthService} from "../../../services/authService";
import {ThemeSwitch} from "../../../navigation/ThemeSwitch";

interface Props {

}

export const SettingsTab: React.FC<Props> = (props) => {
    const { updateApiMode, apiMode } = useContext(UserContext);


    const password = "test123";
    const [showAdminDialog, setShowAdminDialog] = useState(false);
    const [apiModeState, setApiModeState] = useState(apiMode);
    const [apiModeTempValue, setApiModeTempValue] = useState(apiMode);
    const [fontSize, setFontSize] = useState(14);
    const { formatMessage : f } = useIntl();

    const authService = new AuthService();

    useEffect(() => {
        updateApiMode(apiModeState);
    }, [apiModeState])

    const logout = () => authService.logout().then(() => window.location.reload());

    const handleApiModeChange = (value: string) => {
        setApiModeTempValue(value);
        if(value === "dev" || value === "dev2" ||  value === "dev3") {
            setShowAdminDialog(true);
        }else if(value === "prod") {
            setApiModeState("prod");
            setApiModeTempValue("prod");
            logout();
        }
    }

    const handlePasswordChange = (value: string) => {
        if (value === password) {
            setApiModeState(apiModeTempValue);
            setShowAdminDialog(false);
            logout();
        }
    }

    // useEffect(() => {
    //     if((apiModeTempValue === "dev" && apiMode === "dev") || (apiModeTempValue === "dev2" && apiMode === "dev2")) {
    //         authService.login({username: "admin", password: "123654"}).then(resp => console.log("OK"))
    //     }
    // }, [apiMode, apiModeTempValue])

    const apiOptions = [
        {label: 'Production', value: 'prod'},
        {label: 'Dev', value: 'dev'},
        {label: 'Dev2', value: 'dev2'},
        {label: 'Dev3', value: 'dev3'},
    ]

    useEffect(() => {
        document.querySelector("html")!.style.fontSize = fontSize + "px";
        //@ts-ignore
        document.querySelector("#root")!.style.fontSize = fontSize + "px";
    },[fontSize])

    return <>
        <div className={"p-p-3"}>
            <h3>{f({id: "fontSize"})}: {fontSize}</h3>
            <Slider value={fontSize} min={10} max={20} onChange={(e) => setFontSize(+e.value)} />
        </div>
        <div className={"p-p-3"}>
            <h3>{f({id: "chooseApiMode" })}</h3>
            <SelectButton value={apiModeState} options={apiOptions} onChange={(e) => handleApiModeChange(e.value)}/>
        </div>
        <div className={"p-p-3"}>
            <h3>{f({id: "theme" })}</h3>
            <ThemeSwitch />
        </div>
        <Dialog header={`${f({id: "adminPassword"})}: `} visible={showAdminDialog} style={{width: '10vw'}} modal
                onHide={() => {
                    setApiModeTempValue(apiModeState)
                    setShowAdminDialog(false)
                }}>
            <Password autoFocus={true} onChange={(e) => handlePasswordChange(e.target.value)} feedback={false}/>
        </Dialog>
    </>
}
