import * as React from 'react';
import {useContext, useEffect} from 'react';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "./lib/layout/fontawesome6/pro/css/all.min.css";
import 'primeflex/primeflex.css';
import './lib/styles/sidebar.css'
import {Routes, useLocation} from "react-router-dom";
import {RightSideBar} from "./navigation/RightSideBar";
import Footer from "./navigation/Footer";
import {Route} from "react-router";
import {Home} from "./components/Home";
import {Login} from './components/login/Login';
import {AuthService} from "./services/authService";
import {useWindows} from './lib/hooks/useWindows';
import {BackTopBar} from './lib/templates/BackTopBar';
import {useIntl} from 'react-intl';
import {Sidebar} from "@xal3xfx/reactbar";
import {useUserInfo} from './lib/hooks/useUserInfo';
import {Button} from "primereact/button";
import styled from "styled-components";
import {configureApp} from "./config";
import {Circles} from "react-preloaders2";
import UserButton from "./lib/templates/UserButton";
import {PermissionContext} from "./lib/context/PermissionsContext";
import NotFound from "./NotFound";
import {PrimeReactContext} from 'primereact/api';
import {ThemeSwitch} from "./navigation/ThemeSwitch";

const TopbarElementsWrapper = styled.div`
    display: flex;
    width: calc(100% - 50px);
    align-items: center
`

const LogoWrapper = styled.div`
    justify-content: center;
    display: flex;
    margin-top: -0.5rem;
    flex-grow: 10;
`

export const App = (): any => {
    const {formatMessage: f} = useIntl();

    const {changeTheme} = useContext(PrimeReactContext);
    const {userInfo, updateUserInfo, theme} = useUserInfo();
    const {
        setPermissions,
        normalComponentsToRender,
        persistentComponentsToRender,
        sidebarItemsToRender
    } = useContext(PermissionContext);
    const {isComponentMounted, mountedWindows, popWindow, addWindow, setMountedWindows} = useWindows();
    const authService = new AuthService();

    configureApp(f);

    const {pathname} = useLocation();

    const itemsPathMap = {
        "^\\/$": "home",
        "^\\/register$": "register",
        "^\\/serverStatus$": "serverStatus",
        "^\\/proxyStatus": "proxyStatus",
        "^\\/devices$": "devices",
        "^\\/simCards$": "simCards",
        "^\\/employees$": "employees",
        "^\\/carsData$": "carsData",
        "^\\/clientFirms$": "clientFirms",
        "^\\/invoices$": "invoices",
        "^\\/fleet$": "fleet",
        "^\\/vehicles$": "vehicles",
        "^\\/unAssociatedVehicles$": "unAssociatedVehicles",
        "^\\/accounts$": "accounts",
        "^\\/operator$": "operator",
        "^\\/offerConfigurator": "offerConfigurator",
        "^\\/tools": "tools",
        "^\\/teltonikaMetaData": "teltonikaMetaData",
        "^\\/protocols": "protocols",
        "^\\/internalTasks": "internalTasks",
        "^\\/schedule": "schedule",
        "^\\/globalTariffs": "globalTariffs",
    };

    useEffect(() => {
        //This is the mechanism that clears the windows stack, when the user goes out of the stack without pushing a new window to it
        if (!mountedWindows.includes(pathname.split("/")[1])) setMountedWindows([]);
    }, [pathname]);

    useEffect(() => {
        const body = document.body;
        const links = document.querySelectorAll("#theme-link");
        //@ts-ignore
        if (links.length > 1) links[1].remove()
        if (theme === "light" && changeTheme) {
            changeTheme("vela-blue", "saga-blue", "theme-link");
            if (body) {
                body.style.setProperty('--bgColor', 'aliceblue');
            }
        } else if (theme === "dark" && changeTheme) {
            changeTheme("saga-blue", "vela-blue", "theme-link");
            if (body) {
                body.style.setProperty('--bgColor', '#171e29');
                body.style.setProperty('--secondary-bg-color', '#171e29');
            }
        }
    }, [theme])

    useEffect(() => {
        authService.checkIfAuthenticated().then((data) => {
            updateUserInfo({authenticated: true, username: data.username});
            setPermissions(data.permissions)
        }).catch(err => {

        });
    }, []);


    useEffect(() => {
        if (mountedWindows.length === 0) {
            addWindow(window.location.href.split('/').slice(-1)[0]);
        }
    }, []);

    const handleLogout = () => {
        authService.logout().then(() => {
            updateUserInfo({authenticated: false})
        })
    }

    const topbarElements = <TopbarElementsWrapper>
        <LogoWrapper></LogoWrapper>
        <ThemeSwitch/>
        <UserButton/>
        <Button style={{background: "transparent", border: '0px'}} icon='fa-solid fa-right-from-bracket'
                onClick={handleLogout}/>

    </TopbarElementsWrapper>

    const persistentMatch = Object.values(persistentComponentsToRender).find(route => {
        return pathname === "/" || "/" + route.some(el => el.path === pathname);
    });

    const normalMatch = Object.values(normalComponentsToRender).find(route => {
        return pathname === "/" || "/" + route.some(el => el.path === pathname);
    });

    const renderRouters = () => {
        return (
            <React.Fragment>
                {
                    !userInfo.authenticated ?
                        <Login/>
                        :
                        <>
                            <React.Suspense fallback={<Circles customLoading={true}/>}>
                                <div className={theme === "light" ? "layout-white" : "layout-dark"}>
                                    <Sidebar items={sidebarItemsToRender}
                                             topbarStyle={{background: 'linear-gradient(90deg,#0388e5 0,#07bdf4)'}}
                                             topBarElement={topbarElements}
                                             locationPath={pathname}
                                             collapseIcon={"fa fa-chevron-up"}
                                             expandIcon={"fa fa-chevron-down"}
                                             itemPathsMap={itemsPathMap}
                                    >

                                        <div className="layout-wrapper">
                                            {mountedWindows.filter(name => name !== '').length >= 2 &&
                                                <BackTopBar
                                                    prevComponent={f({id: mountedWindows[mountedWindows.length - 2]})}
                                                    goBack={popWindow}/>}
                                            {
                                                //Add the persistent components
                                                Object.keys(persistentComponentsToRender).map((key) => {
                                                    return persistentComponentsToRender[key as keyof typeof persistentComponentsToRender].map((component) => {
                                                        const Component = component.component;
                                                        // return (
                                                        //     <Route
                                                        //         key={component.path} // Ensure a unique key for each route
                                                        //         path={`/${component.path}`}
                                                        //         element={
                                                        //             <div
                                                        //                 style={{display: isComponentMounted(component.path) ? '' : 'none'}}>
                                                        //                 {Component}
                                                        //             </div>
                                                        //         }
                                                        //     />
                                                        // );
                                                        return (
                                                            <div style={{display: isComponentMounted(component.path) ? '' : 'none'}}>
                                                                    {mountedWindows.includes(component.path) && Component}
                                                            </div>

                                                        );
                                                    });
                                                })
                                            }
                                            <Routes>
                                                {
                                                    //Add normal component routes
                                                    Object.keys(normalComponentsToRender).map((key) => {
                                                        return normalComponentsToRender[key as keyof typeof normalComponentsToRender].map((component) => {
                                                            const Component = component.component; // Access the component directly
                                                            return (
                                                                <Route
                                                                    key={component.path} // Ensure unique keys for each route
                                                                    path={`/${component.path}`}
                                                                    // @ts-ignore*/
                                                                    element={Component} // Render the component directly
                                                                />
                                                            );
                                                        });
                                                    })
                                                }

                                                <Route
                                                    path="/"
                                                    element={<Home/>} // Render Home component directly
                                                />

                                                {!persistentMatch && !normalMatch && <NotFound/>}
                                            </Routes>

                                            <RightSideBar/>
                                            <Footer/>
                                        </div>


                                    </Sidebar>
                                </div>

                            </React.Suspense>
                        </>
                }
            </React.Fragment>
        );
    };
    return <>
        {renderRouters()}
    </>
};
