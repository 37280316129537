import axios from "axios";
import {
    ICreateVehicle,
    ICreateVehicleManufacturer,
    IDevice,
    IGPSAccountVehicle,
    ITariff,
    IVehicle,
    IVehicleManufacturer, INote, IStatus
} from "../lib/types/types";
import {UtilService} from "./utilService";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import moment from "moment";
import {AllCarData} from "./backend-api";

export class VehicleService {
    service = "vehicles/";

    async getAllVehicles() {
        return new Promise<IVehicle[]>((resolve, reject) => {
            axios.get(this.service).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getVehicleById(vehicleId: number) {
        return new Promise<IVehicle>((resolve, reject) => {
            axios.get<IVehicle>(this.service + vehicleId)
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async getAllVehiclesWithoutTariffs() {
        return new Promise<IVehicle[]>((resolve, reject) => {
            axios.get(this.service + "notarifs").then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllManufacturers() {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get(this.service + 'manufacturers').then((resp: any) => {
                const optionsFormatted = UtilService.generateDropdownOptionsNewFormatFromData(resp.data, 'vehicleManufacturerId', 'vehicleManufacturerName');
                resolve(optionsFormatted);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllEuros() {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get(this.service + 'euros').then((resp: any) => {
                const optionsFormatted = UtilService.generateDropdownOptionsNewFormatFromData(resp.data, 'vehicleEuroId', 'vehicleEuroName');
                resolve(optionsFormatted);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async saveVehicle(vehicle: Partial<IVehicle>) {
        return new Promise<IVehicle>((resolve, reject) => {
            axios.post<IVehicle>(this.service, vehicle).then((resp) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllModelsForManufacturer(vehicleManufacturerId: number) {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IVehicleManufacturer>(this.service + 'manufacturers/' + vehicleManufacturerId).then((resp) => {
                if(resp.data.manufacturerModels){
                    const optionsFormatted = UtilService.generateDropdownOptionsNewFormatFromData(resp.data.manufacturerModels, 'vehicleModelId', 'vehicleModelName');
                    resolve(optionsFormatted);
                }else{
                    resolve([]);
                }

            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async deleteVehicle(vehicleId : number){
        return new Promise<IVehicle>((resolve, reject) => {
            axios.delete(this.service + vehicleId).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createManufacturer(data: ICreateVehicleManufacturer) {
        return new Promise<IVehicleManufacturer>((resolve, reject) => {
            axios.post<IVehicleManufacturer>(this.service + 'manufacturers/', data).then((resp) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createModel(data: {vehicleModelManufacturerId: number, vehicleModelName: string, vehicleModelYear: number}) {
        return new Promise<IVehicleManufacturer>((resolve, reject) => {
            axios.post<IVehicleManufacturer>(this.service + 'models/', {...data}).then((resp) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getDevicesForVehicle(vehicleId: number){
        return new Promise<IDevice[]>((resolve, reject) => {
            axios.get(this.service + vehicleId +'/devices').then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async assignDevicesToVehicle(deviceId : number, vehicleId: number){
        return new Promise<IDevice[]>((resolve, reject) => {
            axios.post(this.service + vehicleId +'/devices/'+ deviceId).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async unAssignDevicesFromVehicle(deviceId: number, vehicleId: number){
        return new Promise<IDevice[]>((resolve, reject) => {
            axios.delete(this.service + vehicleId +'/devices/' + deviceId).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllTariffsForVehicle(vehicleId: number){
        return new Promise<ITariff[]>((resolve, reject) => {
            axios.get<ITariff[]>(this.service + vehicleId +'/tarifs').then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createTariff(vehicleId: number, tariff: ITariff){
        return new Promise<ITariff[]>((resolve, reject) => {
            axios.post<ITariff[]>(this.service + vehicleId + '/tarifs', tariff).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async deleteTariff(vehicleId: number, tariffId: number){
        return new Promise<ITariff[]>((resolve, reject) => {
            axios.delete<ITariff[]>(this.service + vehicleId + '/tarifs/' + tariffId).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async assignTariffs(tarifIds: number[], vehicleIds: number[], clientFirmId: number){
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(this.service + 'tarifs/assign', {tarifIds, vehicleIds, clientFirmId}).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllFreeVehicles() {
        return new Promise<IVehicle[]>((resolve, reject) => {
            axios.get<IVehicle[]>(this.service + 'free').then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async changeVehicleStatus(vehicleId: number, vehicleStatus: number) {
        return new Promise<IVehicle[]>((resolve, reject) => {
            axios.post<IVehicle[]>(this.service + 'status', {vehicleId, vehicleStatus}).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async loadInvoiceDate(vehicleId: number, invoiceDate: Date) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post<boolean>(this.service + 'invoicedate', {vehicleId, invoiceStartDate: moment(invoiceDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async addGps(vehicleId: number, deviceId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + `addGps/${vehicleId}/${deviceId}`).then(resp => {
                    resolve(resp.data)
                }).catch(err => {
                    console.error(err);
                    reject(err);
            })
        })
    }

    async addNote(vehicleId: number, note: string) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.post(this.service + `${vehicleId}/vehicleNote`, {note}).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    async getVehicleStatuses() {
        return new Promise<IStatus[]>((resolve, reject) => {
            axios.get<IStatus[]>(`${this.service}status`,).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }

    convertGPSAccountVehicle(gpsAccountVehicle: IGPSAccountVehicle) : ICreateVehicle {
        return {
            vehicleManufacturerId: 1,
            clientFirmId: gpsAccountVehicle.clientFirmId,
            vehicleLpn: gpsAccountVehicle.alias || "",
            vehicleEuroId: 1,
            vehicleModelId: 1,
            tacho: false,
            //@ts-ignore
            vehicleTable: gpsAccountVehicle.vehiclE_ID!,
        }
    }

    convertAllCarData(allCarData: AllCarData) : ICreateVehicle {
        return {
            vehicleManufacturerId: 1,
            clientFirmId: allCarData.clientFirmId ?? 0,
            vehicleLpn: allCarData.alias || "",
            vehicleEuroId: 1,
            vehicleModelId: 1,
            //@ts-ignore
            vehicleTable: allCarData.vehiclE_ID!,
            tacho: false
        }
    }
}
