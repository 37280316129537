import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ICreateTariffFormData} from "./MassEditTariffDeprecated";
import {DynamicForm} from "@xal3xfx/react-utils";
import {formElements} from "./tariff-form";
import {ITariff, IVehicle} from "../../../lib/types/types";
import {useToast} from "../../../lib/hooks/useToast";
import {useDialog} from "../../../lib/hooks/useDialog";
import {useConfirmDialog} from "../../../lib/hooks/useConfirmDialog";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {ClientFirmService} from "../../../services/clientFirmService";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";

interface Props {
    selectedTariff: ITariff;
    onSubmitCallback: () => void;
    vehicles: IVehicle[] | undefined;
}

const initialValues: ICreateTariffFormData = {
    tarifId: 0,
    tarifName: "",
    tarifTypeId: 0,
    tarifDescription: "",
    januaryPrice: 0,
    februaryPrice: 0,
    marchPrice: 0,
    aprilPrice: 0,
    mayPrice: 0,
    junePrice: 0,
    julyPrice: 0,
    augustPrice: 0,
    septemberPrice: 0,
    octoberPrice: 0,
    novemberPrice: 0,
    decemberPrice: 0,
}

const MassEditTariff: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const {showToast} = useToast();
    const {hideDialog} = useDialog();
    const {showConfirmDialog} = useConfirmDialog();
    const didMountRef = useRef(false);

    const clientFirmService = new ClientFirmService();

    const [formElementsState, setFormElementsState] = useState({...formElements});
    const [formDataState, setFormDataState] = useState({...initialValues});
    const [tariffTypeOptions, setTariffTypeOptions] = useState<IDropdownOption[]>([]);
    const [priceForAllMonths, setPriceForAllMonths] = useState<number>()

    const formikRef = useRef<any>(undefined);

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
            loadTariffTypeOptionsIntoForm();
        }
    }, []);

    useEffect(() => {
        if (!props.selectedTariff) return;
        const tempInitialValues = {...props.selectedTariff, tarifTypeId: props.selectedTariff.tarifType.tarifTypeId};
        setFormDataState(tempInitialValues);

    }, [props.selectedTariff]);

    const loadTariffTypeOptionsIntoForm = async () => {
        const tariffTypeOptions = await clientFirmService.getAllTariffTypes()
        setTariffTypeOptions(tariffTypeOptions);
        const tempFormElementsState = {...formElementsState};
        tempFormElementsState.tarifTypeId.options = tariffTypeOptions;
        setFormElementsState(tempFormElementsState);
    }

    const onCreate = (values: ICreateTariffFormData) => {
        return new Promise<boolean>((resolve, reject) => {
            const newTariff: any = {...values};
            newTariff.tarifType = {
                tarifTypeId: values.tarifTypeId,
                tarifTypeName: tariffTypeOptions.find(el => el.id === values.tarifTypeId)!.description
            }

            let vehicleLpns;
            let body;

            if (props.vehicles) {
                vehicleLpns = <ul>
                    {props.vehicles.slice(0, 10).map(v => <li>{v.vehicleLpn}</li>)}
                    {props.vehicles.length > 10 && <b>{f({id: "andMore"}, {count: props.vehicles.length - 10})}</b>}
                </ul>
            }

            body = f({id: "confirmMassUpdateTariff"}, {
                vehicleCount: <b>{props.vehicles?.length || 0}</b>,
                vehicleLpns: vehicleLpns
            })


            showConfirmDialog({
                body: body
            }).then(answer => {
                if (answer) {
                    clientFirmService.massUpdateTariff(props.selectedTariff, newTariff, props.vehicles?.map(el => el.vehicleId) || [])
                        .then(resp => {
                            if (resp) {
                                showToast("success", f({id: "done"}), f({id: "tariffUpdated"}));
                                props.onSubmitCallback();
                                hideDialog();
                                resolve(true);
                            }
                        })
                        .catch((err) => {
                            showToast("error", f({id: "error"}), f({id: "exceptionOccurred"}));
                            resolve(false)
                        })
                } else {
                    resolve(false)
                }
            })
        })
    }

    const putPriceForAllMonths = () => {
        if (formikRef && formikRef.current) {
            Object.keys(initialValues).filter(key => key.includes("Price")).forEach(key => formikRef.current.setFieldValue(key, priceForAllMonths));
        }
    }

    return <>
        <div className={"grid w-full"}>
            <div className="p-field col-4">
                <InputNumber name="priceForAllMonths" autoFocus value={priceForAllMonths} className={"w-full"}
                             maxFractionDigits={2}
                             onChange={(e: any) => setPriceForAllMonths(+e.value)}/>
            </div>
            <div className="p-field col-8">
                <Button label={f({id: 'addPriceForAllMonths'})} onClick={putPriceForAllMonths} type={'button'}/>
            </div>
        </div>
        <DynamicForm formElements={formElementsState}
                     setFormikRef={formik => formikRef.current = formik}
                     isUpdate={false}
                     onCreate={onCreate}
                     onUpdate={() => Promise.resolve(false)}
                     initialValues={formDataState}
                     fieldOrder={['tarifName', 'tarifTypeId', 'tarifDescription', 'januaryPrice', 'februaryPrice', 'marchPrice', 'aprilPrice', 'mayPrice', 'junePrice', 'julyPrice', 'augustPrice', 'septemberPrice', 'octoberPrice', 'novemberPrice', 'decemberPrice']}
                     disableSaveButton={props.vehicles === undefined}
                     onCancelUpdate={() => 0}
                     formButtonsClassName={"col-12 md:col-6"}
        />
    </>
};

export default MassEditTariff
