import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ITariff, IVehicle} from "../../../lib/types/types";
import SelectTariffDropdown from "../SelectTariffDropdown";
import MassEditTariff from "./MassEditTariff";

interface Props {
    selectedTariff?: ITariff;
    onSubmitCallback: () => void;
    tariffOptions: ITariff[];
    getVehicles: (sourceTariff: ITariff) => Promise<IVehicle[]>;
}

const MassEditSelectTariffWrapper: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    const [selectedTariffToUpdate, setSelectedTariffToUpdate] = useState<ITariff | undefined>(props.selectedTariff)
    const [vehiclesForCurrentTariff, setVehiclesForCurrentTariff] = useState<IVehicle[]>();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const handleTariffSelection = (tariff: ITariff) => {
        setSelectedTariffToUpdate(tariff);
        setVehiclesForCurrentTariff(undefined);
        props.getVehicles(tariff)
            .then(resp => {
                setVehiclesForCurrentTariff(resp);
            })
    }

    return <>
        <div className={"w-full flex grid my-3"}>
            <div className={"col-12 md:col-4"}>
                <SelectTariffDropdown label={f({id: "tariffForEdit"})} handleTariffSelected={handleTariffSelection}
                                      tariffOptions={props.tariffOptions} selectedTariff={props.selectedTariff}/>
            </div>
        </div>

        {selectedTariffToUpdate && <MassEditTariff onSubmitCallback={props.onSubmitCallback} vehicles={vehiclesForCurrentTariff}
                                                   selectedTariff={selectedTariffToUpdate}/>
        }
    </>
};

export default MassEditSelectTariffWrapper
