import {IEmployee} from "../lib/types/types";
import axios from "axios";
import {UtilService} from "./utilService";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {UtilService as NewUtilService} from '@xal3xfx/react-utils'

export class EmployeeService {
    service = "employee/"


    async getAllEmployees(){
        return new Promise<IEmployee[]>((resolve, reject) => {
            axios.get(this.service).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllEmployeesDropdown(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IEmployee[]>(this.service).then((resp: any) => {
                resolve(NewUtilService.generateDropdownOptionsFromData(resp.data, "employeeId", "employeeName"));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async saveEmployee(employee: Partial<IEmployee>){
        return new Promise<IEmployee>((resolve, reject) => {
            axios.post(this.service ,employee).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    //TODO: Ники трябва да го направи първо
    async getEmployeeById(employeeId: number){
        return new Promise<IEmployee>((resolve, reject) => {
            axios.get(this.service  + employeeId).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async deleteEmployee(employeeId : number){
        return new Promise<boolean>((resolve, reject) => {
            axios.delete(this.service + employeeId).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getPositionsDropdown(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get(this.service + 'positions').then((resp: any) => {
                resolve(UtilService.generateDropdownOptionsNewFormatFromData(resp.data, 'employeePositionId', 'employeePositionName'));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createEmployeePosition(positionName: string){
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + 'positions', {employeePositionName: positionName}).then((resp: any) => {
                if(resp) resolve(true)
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async allowInternalTasksView(employeeId: number, viewAllTasks: boolean){
        return new Promise<boolean>((resolve, reject) => {
            axios.post(this.service + 'internalTasks/view', {employeeId, viewAllTasks}).then((resp: any) => {
                if(resp) resolve(true)
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }
}
