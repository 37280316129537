import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ReactiveTable} from "react-ive-tables";
import {IVehicle} from "../../lib/types/types";
import {Button} from "primereact/button";
import {TariffsForVehicle} from "./TariffsForVehicle";
import {useDialog} from "../../lib/hooks/useDialog";

interface Props {
    vehicles: IVehicle[];
    onSuccessfulOperationCb?: () => void;
}

const VehiclesWithTariff : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showDialog, hideDialog} = useDialog();

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const getVehiclesColumnTemplate = () => {
        return {
            tariffs: (rowData: IVehicle) => <Button label={f({id: "seeTariffs"})}
                                                    onClick={() => handleSeeTariffsButton(rowData)}/>
        }
    }

    const handleSeeTariffsButton = (vehicle: IVehicle) => {
        showDialog({
            newContent: <TariffsForVehicle selectedVehicle={vehicle} clientFirmId={vehicle.clientFirmId} onSuccessfulOperationCb={props.onSuccessfulOperationCb}/>,
            heading: f({id: "tariffsForVehicleLpn"}, {lpn: vehicle.vehicleLpn}),
            style: {maxWidth: "80vw"}
        })
    }

    return <>
        <div className="p-3 flex w-full justify-content-center align-items-center"
             style={{maxWidth: "83vw"}}>
            <ReactiveTable
                showHeader={false}
                wrapperClassName={"w-full"}
                columnOrder={["vehicleLpn", "tariffs"]}
                data={props.vehicles}
                selectionKey={"vehicleLpn"}
                columnTemplate={getVehiclesColumnTemplate()}
                showFilters={false}
            />

        </div>
    </>
};

export default VehiclesWithTariff
