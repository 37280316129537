import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {HeaderButton, ReactiveTable} from "react-ive-tables";
import {ClientFirmService} from "../../services/clientFirmService";
import {
    ClientFirmVehiclesByTariff,
    GlobalTariffSummaryResponse,
    GlobalTariffSummaryResponseWithTariffId, IAccountDetailedPermissions
} from "../../lib/types/types";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {useDialog} from "../../lib/hooks/useDialog";
import {CreateOrEditGlobalTariff} from "../tariffs/CreateOrEditGlobalTariff";
import ClientFirmsWithTariff from "../tariffs/ClientFirmsWithTariff";
import AssignGlobalTariffToClientFirms from "../tariffs/AssignGlobalTariffToClientFirms";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import {useToast} from "../../lib/hooks/useToast";

interface Props {

}

const GlobalTariffs: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showConfirmDialog} = useConfirmDialog();
    const { showToast } = useToast();

    const [globalTariffs, setGlobalTariffs] = useState<GlobalTariffSummaryResponseWithTariffId[]>();
    const [selectedGlobalTariff, setSelectedGlobalTariff] = useState<GlobalTariffSummaryResponseWithTariffId>();
    const [firmsForTariff, setFirmsForTariff] = useState<ClientFirmVehiclesByTariff[]>();


    const {showDialog, hideDialog} = useDialog();

    const clientFirmService = new ClientFirmService();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
            fetchGlobalTariffs();
        }
    }, []);



    useEffect(() => {
        fetchFirmsForGlobalTariff();
    }, [selectedGlobalTariff]);

    const fetchFirmsForGlobalTariff = () => {
        if (!selectedGlobalTariff) return;
        clientFirmService.getClientFirmsVehicleByGlobalTariffId(selectedGlobalTariff.tariff.tarifId, true)
            .then(setFirmsForTariff)
    }

    const fetchGlobalTariffs = () => {
        clientFirmService.getAllGlobalTariffsSummary()
            .then((resp) => {
                setGlobalTariffs(resp?.map(el => {
                    return {...el, tariffId: el.tariff.tarifId}
                }))
            })
    }

    const headerButtons: HeaderButton[] = [
        {
            icon: 'pi pi-plus',
            label: f({id: "create"}),
            className: '',
            onClick: () => showCreateGlobalTariff()
        }
    ]

    const showCreateGlobalTariff = () => {
        showDialog({
            newContent: <CreateOrEditGlobalTariff edit={false} onSubmitCallback={fetchGlobalTariffs} global={true}/>,
            heading: <div> {f({id: "createGlobalTariff"})}</div>,
            style: {minWidth: "60vw"}

        });
    }

    const handleBack = () => {
        setSelectedGlobalTariff(undefined);
        setFirmsForTariff(undefined);
    }

    const showAssignGlobalTariffToClientFirms = () => {
        if (!selectedGlobalTariff) return;
        showDialog({
            newContent: <AssignGlobalTariffToClientFirms globalTariff={selectedGlobalTariff.tariff} onAssignGlobalTariffCb={fetchFirmsForGlobalTariff}
                                                         firmsToIgnore={firmsForTariff?.map(el => el.clientFirm) || []}/>,
            heading: <div> {f({id: "assignTariff"}, {tariffName: selectedGlobalTariff.tariff.tarifName})}</div>,
            style: {minWidth: "60vw"}

        });
    }


    const showDeleteGlobalTariffFromClientFirms = () => {
        if (!selectedGlobalTariff) return;
        showDialog({
            newContent: <AssignGlobalTariffToClientFirms globalTariff={selectedGlobalTariff.tariff}
                                                         firmsToIgnore={firmsForTariff?.map(el => el.clientFirm) || []}/>,
            heading: <div> {f({id: "assignTariff"}, {tariffName: selectedGlobalTariff.tariff.tarifName})}</div>,
            style: {minWidth: "60vw"}

        });
    }

    const headerButtonsForClientFirmsWithTariff: HeaderButton[] = [
        {
            icon: 'pi pi-plus',
            label: f({id: "assign"}),
            className: '',
            onClick: () => showAssignGlobalTariffToClientFirms()
        },
        // {
        //     icon: 'pi pi-trash',
        //     label: f({id: "delete"}),
        //     className: 'p-button-danger ml-2',
        //     onClick: () => showAssignGlobalTariffToClientFirms()
        // }
    ]

    const handleDeleteGlobalTariff = (tariffSummary: GlobalTariffSummaryResponse) => {
        const vehiclesCount = tariffSummary.clientFirmsWithTariff?.reduce((acc, el) => acc + el.vehiclesCount, 0);
        if (vehiclesCount === 0) {
            showConfirmDialog({
                    body: f({id: "confirmDeleteGlobalTariff"}, {tariffName: <b>{tariffSummary.tariff.tarifName}</b>})
            })
                .then(answer => {
                    if (answer) {
                        clientFirmService.deleteGlobalTariff(tariffSummary.tariff.tarifId)
                            .then(resp => {
                                if(resp) {
                                    showToast("success", f({ id: "done" }), f({ id: "deleteGlobalTariffSuccess" }));
                                    fetchGlobalTariffs();
                                }
                            })
                    }
                })
        } else {
            showDialog({
                newContent: f({id: "cannotDeleteGlobalTariffHasVehicles"}, {tariffName: <b>{tariffSummary.tariff.tarifName}</b>, vehiclesCount: <b>{vehiclesCount}</b>}),
                heading: <h3>{f({id: "attention"})}</h3>
            })
        }

    }

    const getColumnTemplate = () => {
        return {
            tarifName: (rowData: GlobalTariffSummaryResponse) => rowData.tariff.tarifName,
            'delete': (rowData: GlobalTariffSummaryResponse) => <div className={"flex justify-content-center"}><Button
                severity={"danger"} icon={"pi pi-trash"}
                onClick={() => handleDeleteGlobalTariff(rowData)}/></div>,
            vehiclesCount: (rowData: GlobalTariffSummaryResponse) => rowData.clientFirmsWithTariff?.reduce((acc, el) => acc + el.vehiclesCount, 0),
        }
    }

    const handleExternalFilter = () => {
        return {
            tarifName: (rowData: GlobalTariffSummaryResponse, filterValue: string) => {
                return rowData.tariff.tarifName.includes(filterValue);
            },
            vehiclesCount: (rowData: GlobalTariffSummaryResponse, filterValue: string) => {
                return rowData.clientFirmsWithTariff?.reduce((acc, el) => acc + el.vehiclesCount, 0).toString() === filterValue;
            }

        }
    }

    return <>
        <Card>
            {!firmsForTariff &&
                <>
                    <h2>{f({id: "globalTariffs"})}</h2>
                    <ReactiveTable
                        columnOrder={['tarifName', 'vehiclesCount', 'delete']}
                        data={globalTariffs}
                        ignoreFilters={["delete"]}
                        selectionKey={"tariffId"}
                        externalFilters={handleExternalFilter()}
                        columnStyle={{delete: {body: {width: "100px"}, header: {}}}}
                        columnTemplate={getColumnTemplate()}
                        showHeader={true}
                        headerButtons={headerButtons}
                        setSelected={setSelectedGlobalTariff}
                    />
                </>
            }

            {selectedGlobalTariff && firmsForTariff &&
                <div className={"w-full flex grid"}>
                    <div className={"col-12 flex flex-column md:flex-row justify-content-start"}>
                        <Button className={"col-12 md:col-1"} label={f({id: "back"})} icon={"pi pi-angle-left"}
                                onClick={handleBack}/>
                        <label
                            className={"col-12 md:col-6 text-2xl font-bold"}>{f({id: "firmsWithThisTariff"}, {tariffName: selectedGlobalTariff.tariff.tarifName})}</label>
                    </div>

                    <ClientFirmsWithTariff firmsForTariff={firmsForTariff} refreshData={fetchFirmsForGlobalTariff}
                                           headerButtons={headerButtonsForClientFirmsWithTariff}/>
                </div>
            }


        </Card>
    </>
};

export default GlobalTariffs
