import * as React from 'react';
import {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {ITariff, IVehicle} from "../../lib/types/types";
import {ReactiveTable} from "react-ive-tables";
import {VehicleService} from "../../services/vehicleService";
import {useConfirmDialog} from "../../lib/hooks/useConfirmDialog";
import {useToast} from "../../lib/hooks/useToast";
import {useDialog} from "../../lib/hooks/useDialog";
import {Button} from "primereact/button";
import styled from "styled-components";
import PreviewTariff from "./PreviewTariff";
import AddTariffToVehicle from "./AddTariffToVehicle";
import {useUserInfo} from "../../lib/hooks/useUserInfo";
import {DataTableBaseProps} from "primereact/datatable";

const HeaderWrapper = styled.div`
    display: flex;
`

const HeaderText = styled.h2`
    margin-left: auto;
    margin-right: auto;
`

const ButtonsContainer = styled.h2`
    display: flex;
    align-items: center;
`


interface Props {
    selectedVehicle?: IVehicle;
    firmTariffs?: ITariff[];
    clientFirmId: number;
    tableHeight?: number;
    refresh?: number;
    onSuccessfulOperationCb?: () => void
}

export const TariffsForVehicle: React.FC<Props> = (props) => {
    const {formatMessage: f} = useIntl();
    const vehicleService = new VehicleService();

    const {showConfirmDialog} = useConfirmDialog();
    const {showToast} = useToast();
    const {showDialog} = useDialog();
    const {isMobile} = useUserInfo();

    const [selectedTariff, setSelectedTariff] = useState<ITariff>();
    const [vehicleTariffsData, setVehicleTariffsData] = useState<ITariff[]>([]);
    const [selectionResetter, setSelectionResetter] = useState(0);

    useEffect(() => {
        getAllTariffsForVehicle();
    }, [props.selectedVehicle]);

    useEffect(() => {
        if (props.refresh) getAllTariffsForVehicle();
    }, [props.refresh])

    useEffect(() => {
        setSelectionResetter(Date.now());
        setSelectedTariff(undefined);
    }, [props.selectedVehicle]);


    const getAllTariffsForVehicle = () => {
        if (!props.selectedVehicle) return;
        vehicleService.getAllTariffsForVehicle(props.selectedVehicle.vehicleId).then(setVehicleTariffsData)
    }

    const addTariff = () => {
        showDialog({
            newContent: <AddTariffToVehicle firmTariffs={props.firmTariffs} selectedVehicle={props.selectedVehicle}
                                            successCb={onSubmitCallback} selectedClientFirmId={props.clientFirmId}/>,
            heading: <div> {f({id: "addTariffToVehicle"}, {lpn: props.selectedVehicle?.vehicleLpn})}</div>,
            style: {minWidth: "35vw"}
        });
    }

    const previewTariff = () => {
        if (!selectedTariff) return;
        showDialog({
            newContent: <PreviewTariff tariff={selectedTariff}/>,
            heading: <div> {f({id: "tariffFormVehicle"}, {lpn: props.selectedVehicle?.vehicleLpn})}</div>,
            style: {minWidth: "35vw"}
        });
    }

    const onSubmitCallback = () => {
        setSelectedTariff(undefined);
        setSelectionResetter(new Date().getTime());
        getAllTariffsForVehicle();
        if(props.onSuccessfulOperationCb) props.onSuccessfulOperationCb();
    }

    const deleteTariff = () => {
        if (!props.selectedVehicle) return
        if (selectedTariff) {
            //@ts-ignore
            showConfirmDialog({body: f({id: "confirmTarifDelete"}, {tarifName: selectedTariff.tarifName})}).then(answer => {
                if (answer) {
                    vehicleService.deleteTariff(props.selectedVehicle!.vehicleId, selectedTariff.tarifId).then(() => {
                        onSubmitCallback();
                    });
                } else {

                }
            })
        }
    }

    const menuModel = [
        {
            label: f({id: "delete"}),
            icon: 'pi pi-trash',
            command: deleteTariff,
            disabled: !selectedTariff,
            className: "p-button-danger"
        },
    ];

    const getHeader = () => {
        if (selectedTariff) menuModel.push(
            {label: f({id: "preview"}), icon: 'pi pi-search', command: previewTariff, disabled: !props.selectedVehicle, className: ""},
        )
        if(props.firmTariffs) menuModel.push(
            {label: f({id: "add"}), icon: 'pi pi-plus', command: addTariff, disabled: !props.selectedVehicle, className: ""},
        )
        return <HeaderWrapper>
            <ButtonsContainer>
                {menuModel.map(el => {
                    return <Button className={"mr-2 " + el.className}
                                   onClick={el.command}
                                   icon={el.icon}
                                   tooltip={el.label}
                                   disabled={el.disabled}
                    />
                })}
            </ButtonsContainer>

            <HeaderText>{f({id: "tariffsFor"}, {for: props.selectedVehicle?.vehicleLpn})}</HeaderText>
        </HeaderWrapper>
    }

    const dtProps : Partial<DataTableBaseProps<ITariff[]>> = {
        pt: {
            thead: {style: {display: isMobile ? "none" : ""}}
        },
        responsiveLayout: "stack",
        header: getHeader()
    }

    return <>
        <ReactiveTable data={vehicleTariffsData} tableHeight={props.tableHeight + "vh" || "auto"}
                       selectionResetter={selectionResetter}
                       columnOrder={['tarifName', 'tarifType.tarifTypeName', 'tarifDescription', 'januaryPrice', 'februaryPrice', 'marchPrice', 'aprilPrice', 'mayPrice', 'junePrice', 'julyPrice', 'augustPrice', 'septemberPrice', 'octoberPrice', 'novemberPrice', 'decemberPrice']}
                       specialLabels={{
                           tarifName: "tariffName",
                           'tarifType.tarifTypeName': "tariffType",
                           tarifDescription: 'description',
                           januaryPrice: 'january',
                           februaryPrice: 'february',
                           marchPrice: "march",
                           aprilPrice: 'april',
                           mayPrice: 'may',
                           junePrice: 'june',
                           julyPrice: 'july',
                           augustPrice: 'august',
                           septemberPrice: 'september',
                           octoberPrice: 'october',
                           novemberPrice: 'november',
                           decemberPrice: 'december'
                       }}
                       selectionKey={'tarifId'}
                       setSelected={setSelectedTariff}
                       dtProps={dtProps}
        />
    </>
};
