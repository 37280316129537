import {FormElementValues} from "@xal3xfx/react-utils";

const tarifName: FormElementValues<'text'> = {
    type: "text",
    label: 'tariffName',
    props: {},
}

const tarifDescription: FormElementValues<'text'> = {
    type: "text",
    label: 'tarifDescription',
    props: {},
}

const tarifTypeId: FormElementValues<'dropdown'> = {
    type: "dropdown",
    label: 'tarifTypeId',
    props: {},
    options: []
}

const januaryPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'january',
    props: {}
}

const februaryPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'february',
    props: {}
}

const marchPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'march',
    props: {}
}

const aprilPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'april',
    props: {}
}

const mayPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'may',
    props: {}
}

const junePrice: FormElementValues<'number'> = {
    type: "number",
    label: 'june',
    props: {}
}

const julyPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'july',
    props: {}
}

const augustPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'august',
    props: {}
}

const septemberPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'september',
    props: {}
}

const octoberPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'october',
    props: {}
}

const novemberPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'november',
    props: {}
}

const decemberPrice: FormElementValues<'number'> = {
    type: "number",
    label: 'december',
    props: {}
}


export const formElements = {
    tarifName,
    tarifTypeId,
    tarifDescription,
    januaryPrice,
    februaryPrice,
    marchPrice,
    aprilPrice,
    mayPrice,
    junePrice,
    julyPrice,
    augustPrice,
    septemberPrice,
    octoberPrice,
    novemberPrice,
    decemberPrice,
}
