import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ITariff, ITrafficRow} from "../../lib/types/types";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {FloatLabel} from "primereact/floatlabel";

interface Props {
    tariffOptions: ITariff[];
    handleTariffSelected: (tariff: ITariff) => void;
    selectedTariff?: ITariff;
    label: string;
    excludeTariff?: ITariff;
}

interface ITariffWithId extends ITariff {
    id: string
}

const SelectTariffDropdown : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    const [tariffOptions, setTariffOptions] = useState<ITariffWithId[]>();
    const [selectedTariffForDropdown, setSelectedTariffForDropdown] = useState<string>()

    useEffect(() => {
        if (!props.selectedTariff) return;
        const id = generateTariffKey(props.selectedTariff);
        setSelectedTariffForDropdown(id)
        props.handleTariffSelected(props.selectedTariff)
    }, [props.selectedTariff])

    useEffect(() => {
        if (!props.tariffOptions) return;


       const uniqueTariffOptions = props.tariffOptions
            .map((el: ITariff) => ({
                ...el,
                id: generateTariffKey(el),
            }))
            .reduce((acc: Record<string, ITariffWithId>, current) => {
                //Exclude the excludeTariff if present
                if(props.excludeTariff && current.id === generateTariffKey(props.excludeTariff)) {
                    return acc
                }
                if (!acc[current.id]) {
                    acc[current.id] = current;
                }
                return acc;
            }, {});

        setTariffOptions(Object.values(uniqueTariffOptions));
    }, [props.tariffOptions, props.excludeTariff]);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    const generateTariffKey = (tariff: ITariff) => {
        return `${tariff.tarifName}-${tariff.tarifType.tarifTypeId}-${tariff.tarifDescription}-${tariff.januaryPrice}-${tariff.februaryPrice}-${tariff.marchPrice}-${tariff.aprilPrice}-${tariff.mayPrice}-${tariff.junePrice}-${tariff.julyPrice}-${tariff.augustPrice}-${tariff.septemberPrice}-${tariff.octoberPrice}-${tariff.novemberPrice}-${tariff.decemberPrice}`
    }

    const handleTariffSelection = (e: DropdownChangeEvent) => {
        //@ts-ignore
        props.handleTariffSelected(tariffOptions?.find(el => el.id === e.value))
        setSelectedTariffForDropdown(e.value);
    }

    return <>
        <FloatLabel>
            <Dropdown options={tariffOptions} optionLabel={"tarifName"} optionValue={"id"}
                      className={"w-full"} filter={true} virtualScrollerOptions={{itemSize: 30}}
                      value={selectedTariffForDropdown} onChange={handleTariffSelection}/>
            <label>{props.label}</label>
        </FloatLabel>
    </>
};

export default SelectTariffDropdown
