import React, {createContext, useEffect, useState} from 'react';
import {apiUrl} from '../config/Config'
import {UserInfo} from "../types/types";

export const UserContext = createContext<any>(null);



export const UserContextProvider = (props: any) => {
    const initialState: UserInfo = {
        authenticated: false,
        username: ''
    }

    const [theme, setTheme] = useState<"light" | "dark">((localStorage.getItem("theme") as "light" | "dark") || "light")
    const [userInfo, setUserInfo] = useState(initialState);
    const [isMobile, setIsMobile] = useState(false);
    const [apiMode, setApiMode] = useState<'prod' | 'dev' | 'dev2' | 'dev3'>('prod');

    useEffect(() => {
        let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        setIsMobile(width <= 960);
        const onResize = () => {
            width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
            setIsMobile(width <= 960);
        }
        window.addEventListener("resize", (onResize));

        return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        //Get api mode from local storage

        const env = localStorage.getItem('env');
        if(env !== null && (env === 'prod' || env === 'dev' || env === 'dev2' || env === 'dev3')){
            setApiMode(env);
        }

        const localStorageTheme = localStorage.getItem('theme');
        if(localStorageTheme !== null && (localStorageTheme === "light" || localStorageTheme === "dark")) {
            setTheme(localStorageTheme);
        }

    }, []);

    useEffect(() => {
        if(localStorage.getItem("env")) {
            setApiMode(localStorage.getItem("env") as "dev" | "dev2" | "dev3" |  "prod");
        }
    }, [localStorage.getItem("env")])

    const updateApiMode = (apiMode : 'prod' | 'dev' | 'dev2' | 'dev3') => {
        localStorage.setItem('env', apiMode);
        setApiMode(apiMode);
    }


    const updateUserInfo = (userInfo: Partial<UserInfo>) => {
        setUserInfo(prevState => { return { ...prevState, ...userInfo } });
    }

    const getApi = () => {
        return apiMode === "prod" ? apiUrl.prod : apiUrl.dev;
    }

    const handleThemeChange = (theme: "light" | "dark") => {
        setTheme(theme);
        localStorage.setItem("theme", theme);
    }

    return <>
        <UserContext.Provider value={{ userInfo, updateUserInfo, updateApiMode, apiMode, getApi, theme, handleThemeChange, isMobile }}>
            {props.children}
        </UserContext.Provider>
    </>
};
