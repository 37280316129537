import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {GlobalTariffAssignResponse, IClientFirm, ITariff} from "../../lib/types/types";
import {ClientFirmService} from "../../services/clientFirmService";
import {ReactiveTable} from "react-ive-tables";
import {Button} from "primereact/button";

interface Props {
    globalTariff: ITariff;
    firmsToIgnore: IClientFirm[];
    onAssignGlobalTariffCb?: () => void;
}

const AssignGlobalTariffToClientFirms: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const [clientFirms, setClientFirms] = useState<IClientFirm[]>();
    const [selectedClientFirms, setSelectedClientFirms] = useState<IClientFirm[]>([]);
    const [assignResponseStatus, setAssignResponseStatus] = useState<GlobalTariffAssignResponse[]>()

    let clientFirmService = new ClientFirmService();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;

            clientFirmService.getAllClientFirms()
                .then(setClientFirms)
        }
    }, []);

    const handleSave = () => {
        if (selectedClientFirms.length <= 0) return;
        clientFirmService.assignGlobalTariffToClientFirms(props.globalTariff.tarifId, selectedClientFirms.map(el => el.clientFirmId))
            .then(resp => {
                setAssignResponseStatus(resp);
                if(props.onAssignGlobalTariffCb) props.onAssignGlobalTariffCb();
            })
    }

    const handleCancel = () => {

    }

    const getColumnTemplate = () => {
        return {
            clientFirmId: (rowData: GlobalTariffAssignResponse) => clientFirms?.find(el => el.clientFirmId === rowData.clientFirmId)?.clientFirmName || "",
            globalTariffId: (rowData: GlobalTariffAssignResponse) => props.globalTariff.tarifName,
            success: (rowData: GlobalTariffAssignResponse) => <i style={{color: rowData.success ? '#46b04a' : '#fc0303'}}
                                                                                                       className={rowData.success ? 'fas fa-check-circle fa-2x' : 'fas fa-times-circle fa-2x'}/>,
        }
    }
    return <>
        {assignResponseStatus ? <>
                <ReactiveTable
                    data={assignResponseStatus}
                    columnOrder={['clientFirmId', 'globalTariffId', 'success', 'message']}
                    columnTemplate={getColumnTemplate()}
                    specialLabels={{
                        clientFirmId: 'clientFirm',
                        globalTariffId: 'globalTariff',
                        success: 'result',
                        message: "responseMessage"
                    }}
                />
            </>
            :
            <>
                <h3>{f({id: "selectedFirmsCount"}, {count: selectedClientFirms?.length})}</h3>

                <ReactiveTable
                    columnOrder={['clientFirmName', 'salesPersonName']}
                    data={clientFirms}
                    selectionMode={"checkbox"}
                    setSelected={setSelectedClientFirms}
                    selectionKey={"clientFirmId"}
                />

                <div className={"flex"}>
                    <Button label={f({id: "save"})} onClick={handleSave}/>
                    <Button label={f({id: "cancel"})} onClick={handleCancel}/>
                </div>
            </>
        }
    </>
};

export default AssignGlobalTariffToClientFirms
