import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {HeaderButton, ReactiveTable} from "react-ive-tables";
import {
    ClientFirmVehiclesByTariff,
    IClientFirm,
} from "../../lib/types/types";
import {DataTableRowToggleEvent} from "primereact/datatable";
import VehiclesWithTariff from "./VehiclesWithTariff";
import {UtilService} from "../../services/utilService";
import {Dropdown} from "primereact/dropdown";
import {useDialog} from "../../lib/hooks/useDialog";
import {useUserInfo} from "../../lib/hooks/useUserInfo";

interface Props {
    firmsForTariff: ClientFirmVehiclesByTariff[];
    headerButtons?: HeaderButton[];
    refreshData?: () => void
}

const ClientFirmsWithTariff: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const {showDialog, hideDialog} = useDialog();
    const {isMobile} = useUserInfo();


    const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>([]);
    // const [isMobile, setIsMobile] = useState(false);
    const [selectedClientFirm, setSelectedClientFirm] = useState<IClientFirm>();

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    // useEffect(() => {
    //     let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    //     setIsMobile(width <= 960);
    //     const onResize = () => {
    //         width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    //         setIsMobile(width <= 960);
    //     }
    //     window.addEventListener("resize", (onResize));
    //
    //     return () => window.removeEventListener('resize', onResize);
    // }, []);

    useEffect(() => {
        if(!isMobile || !selectedClientFirm) return;

        showDialog({
            newContent: rowExpansionTemplate(selectedClientFirm),
            heading: f({id: 'vehicle'})
        })
    }, [selectedClientFirm]);

    const getClientFirmsColumnTemplate = () => {
        return {
            vehiclesCount: (rowData: IClientFirm) => props.firmsForTariff!.find(el => el.clientFirm.clientFirmId === rowData.clientFirmId)?.vehicles.length,
            clientFirmHasThisGlobalTariff: (rowData: IClientFirm) => props.firmsForTariff!.find(el => el.clientFirm.clientFirmId === rowData.clientFirmId)?.clientFirmHasThisGlobalTariff ? "Да" : "Не",
        }
    }

    const rowExpansionTemplate = (data: IClientFirm) => {
        const vehiclesForFirm = props.firmsForTariff!.find(el => el.clientFirm.clientFirmId === data.clientFirmId);
        if (vehiclesForFirm) {
            return <VehiclesWithTariff vehicles={vehiclesForFirm.vehicles} onSuccessfulOperationCb={props.refreshData}/>
        }

    };

    const handleRowToggle = (e: DataTableRowToggleEvent) => {
        setExpandedRows(e.data);
    }

    const getSpecialFilters = () => {
        return {
            clientFirmHasThisGlobalTariff: (options: any) => <Dropdown showClear value={options.value}
                                                                       optionValue={"id"} optionLabel={"description"}
                                                                       options={UtilService.yesNoOptions}
                                                                       placeholder={f({id: 'choose'})}
                                                                       onChange={(e) => options.filterApplyCallback(e.value)}
                                                                       style={{textAlign: "left"}}/>,
        }
    }

    const handleExternalFilter = () => {
        return {
            clientFirmHasThisGlobalTariff: (rowData: IClientFirm, filterValue: string) => {
                //@ts-ignore
                return props.firmsForTariff!.find(el => el.clientFirm.clientFirmId === rowData.clientFirmId)?.clientFirmHasThisGlobalTariff === filterValue
            }

        }
    }

    return <>
        <div className="p-3 flex w-full justify-content-center align-items-center">
            <ReactiveTable
                columnOrder={["clientFirmName", "salesPersonName", "vehiclesCount", "clientFirmHasThisGlobalTariff"]}
                data={props.firmsForTariff.map(el => el.clientFirm)}
                showFilters={!isMobile}
                showPaginator={false}
                wrapperClassName={"w-full"}
                selectionKey={"clientFirmId"}
                setSelected={setSelectedClientFirm}
                expandable={!isMobile}
                externalFilters={handleExternalFilter()}
                showHeader={true}
                specialFilters={getSpecialFilters()}
                headerButtons={props.headerButtons}
                columnTemplate={getClientFirmsColumnTemplate()}
                paginatorOptions={[100000]}
                specialLabels={{
                    vehiclesCount: "vehiclesWithSelectedTariff"
                }}
                dtProps={{
                    rowExpansionTemplate: isMobile ? undefined : rowExpansionTemplate,
                    expandedRows: isMobile ? [] : expandedRows,
                    onRowToggle: isMobile ? undefined : handleRowToggle,
                    responsiveLayout: "stack",
                    pt: {
                        thead: {style: {display: isMobile ? "none" : ""}}
                    }
                }}
            />

        </div>
    </>
};

export default ClientFirmsWithTariff
